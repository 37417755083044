import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import { Container, Row, Col, Form, Button, Modal, Alert } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css'; 

// data-picker
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { itIT } from "@mui/x-date-pickers/locales";
import 'dayjs/locale/it.js';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

// table
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';

import Grid from "@mui/material/Grid2";

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardHeader, Avatar, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {
  FavoriteBorderRounded,
  ShareOutlined,
  MoreVert
} from "@mui/icons-material";


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import ModalAppuntamento from "./ModalAppuntamento.js";

import dayjs from 'dayjs'
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';

import { agendaOraAppuntamenti } from './../../constants/agendaOraAppuntamenti.ts';

// variabili di sessione
import { useDispatch, useSelector } from "react-redux";

// costanti generali
const altezza_cella_appuntamento_px=60;                 // altezza_cella_appuntamento_px => height:90  
const altezza_cella_appuntamento1_px=60;                // altezza_cella_appuntamento1_px => height:100  
const colorBackground_cella_senzaAppuntamento='#fCfcfc';

const resourcesInit = require("../../constants/resourcesInit.js")
const appointmentsInit = require("../../constants/appointmentsInit.js")

const daysNomeEsteso = ['Domenica','Lunedì','Martedì','Mercoledì','Giovedì','Venerdì','Sabato'];
const monthsNomeEsteso = ['Gennaio','Febbraio','Marzo','Aprile','Maggio','Giugno','Luglio','Agosto','Settembre','Ottobre','Novembre','Dicembre'];

const ProdottiPageComponent = ({ loginUserApiRequest,reduxDispatch, setReduxUserState  }) => {

  // con useSelector recupero 'userRegisterLogin' dalle variabili di sessione = reducer.userRegisterLogin: userRegisterLoginReducer 
  const { userInfo } = useSelector((state) => state.userRegisterLogin);
   
  const [validated, setValidated] = useState(false);
  const [loginUserResponseState, setLoginUserResponseState] = useState({
    success: "",
    error: "",
    loading: false,
  });

  const navigate = useNavigate();

  const config = require("../../constants/constants.js")

  const [isOpenTabAppuntamento, setIsOpenTabAppuntamento] = useState(false);

  const optionsForDate = {'weekday': 'long', 'month': '2-digit', 'day': '2-digit'};

  // è la data di pagina = la data di cui vedo gli appuntamenti
  const [dataAppuntamentiMillis, setDataAppuntamentiMillis] = useState(dayjs(new Date()));                                     // oggetto
 
  // è la data passata al Modal per Edit = stringa formato USA (MongoDB)
  const [modalAppuntamentoGiorno, setModalAppuntamentoGiorno] = useState(dayjs(new Date()).format('YYYY-MM-DD'));  
 
  const [modalAppuntamentoOra, setModalAppuntamentoOra] = useState("");
  const [modalAppuntamentoRiga, setModalAppuntamentoRiga] = useState(0);
  const [modalAppuntamentoColonna, setModalAppuntamentoColonna] = useState(0);

  const [modalAppuntamentoTitoloRisorsa, setModalAppuntamentoTitoloRisorsa] = useState("");
  const [modalAppuntamentoCliente, setModalAppuntamentoCliente] = useState("");
  const [modalAppuntamentoCategoria, setModalAppuntamentoCategoria] = useState("");
  const [modalAppuntamentoProdotto, setModalAppuntamentoProdotto] = useState("");
  const [modalAppuntamentoSito, setModalAppuntamentoSito] = useState("");
  const [modalAppuntamentoDurata, setModalAppuntamentoDurata] = useState(0);
  const [modalAppuntamentoColore, setModalAppuntamentoColore] = useState("");
  const [modalAppuntamentoOwner, setModalAppuntamentoOwner] = useState("");
  const [modalAppuntamentoBookingDateTime, setModalAppuntamentoBookingDateTime] = useState("");
  const [modalAppuntamentoBookingPrice, setModalAppuntamentoBookingPrice] = useState("");
  const [modalAppuntamentoBookingCard, setModalAppuntamentoBookingCard] = useState("");

  const [idConfig, setIdConfig] = useState("");                      
  const [dayAppointmentsInEdit, setDayAppointmentsInEdit] = useState("");                      
  const [responseRequestDay, setResponseRequestDay] = useState("");                      

  const [agenda, setAgenda] = useState({});
  const [resources, setResources] = useState([]);

  const openModal = () => {
    console.log(`opening modal, currently isOpenTabAppuntamento is == ${isOpenTabAppuntamento}`);
    setIsOpenTabAppuntamento(true);
  };

  const closeModalAppuntamento = () => {
    console.log(`closing modal, currently isOpenTabAppuntamento is == ${isOpenTabAppuntamento}`);
    setIsOpenTabAppuntamento(false);
  };

  const modalActionAppuntamento = () => {
    console.log("perform custom action with modal inputs...");
    closeModalAppuntamento();
  };

  const handleMouseDown = e => {
    e.preventDefault();
    console.log("background click");
  };
  
  const getResourceApiRequest = async () => {
    const url = `/api/resources`;
    const { data } = await axios.get(url);
    return data
  }

  const getAppointmentDayMillisApiRequest = async (dataMillis) => {
    console.log("leggo agendaDay di ",dayjs(dataMillis.$d).format('YYYY-MM-DD'));
    const url = `/api/agendas/day/`+dayjs(dataMillis.$d).format('YYYY-MM-DD');
    const { data } = await axios.get(url);
    return data
  }

const getConfigByProgressivoApiRequest = async (progressivoRagSoc) => {
  console.log("[getConfigByProgressivoApiRequest] leggo const con progressivo =",progressivoRagSoc);
  const url = `/api/configs/progressivo/`+progressivoRagSoc;
  const { data } = await axios.get(url);
  return data
}

const updateConfigDayAppointmentInEditByProgressivoApiRequest = async (progressivoRagSoc,dayAppointmentsInEdit) => {
  console.log("[updateConfigDayAppointmentInEditByProgressivoApiRequest] put config con progressivo =",progressivoRagSoc);
  console.log("[updateConfigDayAppointmentInEditByProgressivoApiRequest] put config value  =",dayAppointmentsInEdit);
  const { data } = await axios.put(`/api/configs/progressivoRagioneSociale/${progressivoRagSoc}`, { dayAppointmentsInEdit });
  return data
}

const createAgendaApiRequest = async (formInputs) => {
  console.log(' [createAgendaApiRequest] formInputs=',formInputs);
  const { data } = await axios.post(`/api/agendas`, { ...formInputs });
  return data;
};

const clickCellaAppuntamento = (event, riga, colonna, cliente, categoria, servizio, sito) => {

  // è la funzione chiamata al click sul riquadro di ogno appuntamento

  console.log(`[clickCellaAppuntamento] riga =`, riga);  
  console.log(`[clickCellaAppuntamento] colonna  =`, colonna);  
  console.log(`[clickCellaAppuntamento] appointment  =`, agenda.resource0001appointments[riga].appointment);  

  // riga = orario  

  // colonna = risorsa => decide che array
  let rigaAppointment = colonna;     
  setModalAppuntamentoTitoloRisorsa(agenda.columnResource[colonna].resourceTitle);
  if (colonna===0) {
    setModalAppuntamentoCliente(agenda.resource0001appointments[riga].appointment.customer);
    setModalAppuntamentoCategoria(agenda.resource0001appointments[riga].appointment.category);
    setModalAppuntamentoProdotto(agenda.resource0001appointments[riga].appointment.product);
    setModalAppuntamentoSito(agenda.resource0001appointments[riga].appointment.site);
    setModalAppuntamentoDurata(agenda.resource0001appointments[riga].appointment.durationMins);
    setModalAppuntamentoColore(agenda.resource0001appointments[riga].appointment.color);
    setModalAppuntamentoOwner(agenda.resource0001appointments[riga].appointment.owner);
    setModalAppuntamentoOra(agenda.resource0001appointments[riga].hour);
    setModalAppuntamentoBookingDateTime(agenda.resource0001appointments[riga].appointment.bookingData)
    setModalAppuntamentoBookingPrice(agenda.resource0001appointments[riga].appointment.bookingPrice)
    setModalAppuntamentoBookingCard(agenda.resource0001appointments[riga].appointment.bookingCard)
  }
  if (colonna===1) {
    setModalAppuntamentoCliente(agenda.resource0002appointments[riga].appointment.customer);
    setModalAppuntamentoCategoria(agenda.resource0002appointments[riga].appointment.category);
    setModalAppuntamentoProdotto(agenda.resource0002appointments[riga].appointment.product);
    setModalAppuntamentoSito(agenda.resource0002appointments[riga].appointment.site);
    setModalAppuntamentoDurata(agenda.resource0002appointments[riga].appointment.durationMins);
    setModalAppuntamentoColore(agenda.resource0002appointments[riga].appointment.color);
    setModalAppuntamentoOwner(agenda.resource0002appointments[riga].appointment.owner);
    setModalAppuntamentoOra(agenda.resource0002appointments[riga].hour);
    setModalAppuntamentoBookingDateTime(agenda.resource0002appointments[riga].appointment.bookingData)
    setModalAppuntamentoBookingPrice(agenda.resource0002appointments[riga].appointment.bookingPrice)
    setModalAppuntamentoBookingCard(agenda.resource0002appointments[riga].appointment.bookingCard)
  }
  if (colonna===2) {
    setModalAppuntamentoCliente(agenda.resource0003appointments[riga].appointment.customer);
    setModalAppuntamentoCategoria(agenda.resource0003appointments[riga].appointment.category);
    setModalAppuntamentoProdotto(agenda.resource0003appointments[riga].appointment.product);
    setModalAppuntamentoSito(agenda.resource0003appointments[riga].appointment.site);
    setModalAppuntamentoDurata(agenda.resource0003appointments[riga].appointment.durationMins);
    setModalAppuntamentoColore(agenda.resource0003appointments[riga].appointment.color);
    setModalAppuntamentoOwner(agenda.resource0003appointments[riga].appointment.owner);
    setModalAppuntamentoOra(agenda.resource0003appointments[riga].hour);
    setModalAppuntamentoBookingDateTime(agenda.resource0003appointments[riga].appointment.bookingData)
    setModalAppuntamentoBookingPrice(agenda.resource0003appointments[riga].appointment.bookingPrice)
    setModalAppuntamentoBookingCard(agenda.resource0003appointments[riga].appointment.bookingCard)
  }
  if (colonna===3) {
    setModalAppuntamentoCliente(agenda.resource0004appointments[riga].appointment.customer);
    setModalAppuntamentoCategoria(agenda.resource0004appointments[riga].appointment.category);
    setModalAppuntamentoProdotto(agenda.resource0004appointments[riga].appointment.product);
    setModalAppuntamentoSito(agenda.resource0004appointments[riga].appointment.site);
    setModalAppuntamentoDurata(agenda.resource0004appointments[riga].appointment.durationMins);
    setModalAppuntamentoColore(agenda.resource0004appointments[riga].appointment.color);
    setModalAppuntamentoOwner(agenda.resource0004appointments[riga].appointment.owner);
    setModalAppuntamentoOra(agenda.resource0004appointments[riga].hour);
    setModalAppuntamentoBookingDateTime(agenda.resource0004appointments[riga].appointment.bookingData)
    setModalAppuntamentoBookingPrice(agenda.resource0004appointments[riga].appointment.bookingPrice)
    setModalAppuntamentoBookingCard(agenda.resource0004appointments[riga].appointment.bookingCard)
  }
  if (colonna===4) {
    setModalAppuntamentoCliente(agenda.resource0005appointments[riga].appointment.customer);
    setModalAppuntamentoCategoria(agenda.resource0005appointments[riga].appointment.category);
    setModalAppuntamentoProdotto(agenda.resource0005appointments[riga].appointment.product);
    setModalAppuntamentoSito(agenda.resource0005appointments[riga].appointment.site);
    setModalAppuntamentoDurata(agenda.resource0005appointments[riga].appointment.durationMins);
    setModalAppuntamentoColore(agenda.resource0005appointments[riga].appointment.color);
    setModalAppuntamentoOwner(agenda.resource0005appointments[riga].appointment.owner);
    setModalAppuntamentoOra(agenda.resource0005appointments[riga].hour);
    setModalAppuntamentoBookingDateTime(agenda.resource0005appointments[riga].appointment.bookingData)
    setModalAppuntamentoBookingPrice(agenda.resource0005appointments[riga].appointment.bookingPrice)
    setModalAppuntamentoBookingCard(agenda.resource0005appointments[riga].appointment.bookingCard)
  }
  if (colonna===5) {
    setModalAppuntamentoCliente(agenda.resource0006appointments[riga].appointment.customer);
    setModalAppuntamentoCategoria(agenda.resource0006appointments[riga].appointment.category);
    setModalAppuntamentoProdotto(agenda.resource0006appointments[riga].appointment.product);
    setModalAppuntamentoSito(agenda.resource0006appointments[riga].appointment.site);
    setModalAppuntamentoDurata(agenda.resource0006appointments[riga].appointment.durationMins);
    setModalAppuntamentoColore(agenda.resource0006appointments[riga].appointment.color);
    setModalAppuntamentoOwner(agenda.resource0006appointments[riga].appointment.owner);
    setModalAppuntamentoOra(agenda.resource0006appointments[riga].hour);
    setModalAppuntamentoBookingDateTime(agenda.resource0006appointments[riga].appointment.bookingData)
    setModalAppuntamentoBookingPrice(agenda.resource0006appointments[riga].appointment.bookingPrice)
    setModalAppuntamentoBookingCard(agenda.resource0006appointments[riga].appointment.bookingCard)
  }

  setModalAppuntamentoRiga(riga);
  setModalAppuntamentoColonna(colonna);   
  console.log(`[clickCellaAppuntamento] risorsa  =`, modalAppuntamentoTitoloRisorsa);  
  console.log(`[clickCellaAppuntamento] categoria =`, modalAppuntamentoCategoria);  
  console.log(`[clickCellaAppuntamento] servizio  =`, modalAppuntamentoProdotto);  
  console.log(`[clickCellaAppuntamento] sito =`, modalAppuntamentoSito);  
  console.log(`[clickCellaAppuntamento] owner =`, modalAppuntamentoOwner);  

  openModal(); 
};


function pianificaAppointmentDayOnDB (eventKey,giornoAgendaMillis) {

  // è la function chiamata al click del pulsante CREA
 
  eventKey.preventDefault();
  eventKey.stopPropagation();

  console.log(`[pianificaAppointmentDayOnDB] verifico giornoAgendaMillis = `,giornoAgendaMillis);

  // verifica se agendaDay già esiste
  // http://localhost:3000/api/agendas/day/2024-12-05

  getAppointmentDayMillisApiRequest(giornoAgendaMillis).then((data) => 
    {
      setAgenda(data);
      console.log('response agenda =>',agenda,'<');
      console.log('response agenda =>',agenda,'<');
    })  
    .catch((er) => {
      console.log(er);
      }
    );  

  //if (agenda==='KO')
  //if (agenda.includes("KO")) 
  //if (agenda.day.length>2)
  if(agenda.day !== null && agenda.day !== '') 
    {
    console.log(`day nuovo nel DB quindi da creare = `,giornoAgendaMillis);
    console.log(`___ creo giorno nuovo nel DB  = `,giornoAgendaMillis);

    const formInputs = {
      day: dayjs(giornoAgendaMillis.$d).format('YYYY-MM-DD'),
      dayOfYear: 300,
      weekOfYear: 40,
      title: "",
      description: "",
      note: "",
      columnResource: resourcesInit,
      resource0001appointments: appointmentsInit,
      resource0002appointments: appointmentsInit,
      resource0003appointments: appointmentsInit,
      resource0004appointments: appointmentsInit,
      resource0005appointments: appointmentsInit,
      resource0006appointments: appointmentsInit
    };
    
    setAgenda(formInputs);

    /*
    getAppointmentDayMillisApiRequest(giornoAgendaMillis).then((data) => 
      {
        setAgenda(data);
        console.log('response agenda =>',agenda,'<');
        console.log('response agenda =>',agenda,'<');
      })  
      .catch((er) => {
        console.log(er);
      }
    );  
    */

    setDataAppuntamentiMillis(giornoAgendaMillis);                                       
    setModalAppuntamentoGiorno(dayjs(giornoAgendaMillis.$d).format('YYYY-MM-DD'));
    console.log(`[pianificaAppointmentDayOnDB] agenda creata nuova = `,agenda);

    createAgendaApiRequest(formInputs);

    updateConfigDayAppointmentInEditByProgressivoApiRequest('0001',dayjs(giornoAgendaMillis.$d).format('YYYY-MM-DD'));

  }
    else
  {
    console.log(`day già esistente nel DB           giorno = `,giornoAgendaMillis);
    console.log(`day già esistente nel DB     agenda letta = `,agenda);
  }

}

  // ------ INI ---------------  useEffect 

  /*
  useEffect(() => {
    getConfigByProgressivoApiRequest('0001').then((data) => { 
      console.log(`getConfigByProgressivoApiRequest data =`, data);  
      setIdConfig(data?._id);
      setDayAppointmentsInEdit(data?.dayAppointmentsInEdit);
      console.log(`idConfig =`, data?._id);  
      console.log(`dayAppointmentsInEdit =`, data?.dayAppointmentsInEdit);  

      setDataAppuntamentiMillis(dayjs(data?.dayAppointmentsInEdit)); 

      setModalAppuntamentoGiorno(dayjs(dataAppuntamentiMillis).format('YYYY-MM-DD'));
      console.log(`[useEffect] dataAppuntamentiMillis = `,dataAppuntamentiMillis);
      console.log(`[useEffect] modalAppuntamentoGiorno = `,modalAppuntamentoGiorno);
      console.log(`[useEffect] dayjs(new Date()) = `,dayjs(new Date()));
      console.log(`[useEffect] dayjs(new Date()) = `,dayjs(new Date()).format('YYYY-MM-DD'));
    
      console.log(`[useEffect] verifica pianificaAppointmentDayOnDB `);
      pianificaAppointmentDayOnDB(dataAppuntamentiMillis);
  
    })
    .catch((er) => {
      console.log(er);
    });  
  }, [dayAppointmentsInEdit,setIdConfig])
 */

  useEffect(() => {
    getResourceApiRequest().then((res) => setResources(res))
    .catch((er) => {
      console.log(er);
    });  
  }, [])

  useEffect(() => {
    getAppointmentDayMillisApiRequest(dataAppuntamentiMillis).then((data) => 
      {
        
        console.log(`[useEffect] userInfo = `,userInfo);

        console.log(`[useEffect] data.resource0006appointments[0].appointments = `,data.resource0006appointments[0].appointments);
        setAgenda(data)
        console.log(`[useEffect] agenda = `,agenda);
      })  
      .catch((er) => {
      console.log(er);
    });  
  }, [] )

  /*
  useEffect(() => {
    //setModalAppuntamentoGiorno("2024-12-11");

    // è la data di pagina = la data di cui dedo gli appuntamenti
    setDataAppuntamentiMillis(dayjs(new Date())); 

    setModalAppuntamentoGiorno(dayjs(dataAppuntamentiMillis).format('YYYY-MM-DD'));
    console.log(`[useEffect] dataAppuntamentiMillis = `,dataAppuntamentiMillis);
    console.log(`[useEffect] modalAppuntamentoGiorno = `,modalAppuntamentoGiorno);
    console.log(`[useEffect] dayjs(new Date()) = `,dayjs(new Date()));
    console.log(`[useEffect] dayjs(new Date()) = `,dayjs(new Date()).format('YYYY-MM-DD'));
  
    console.log(`[useEffect] verifica pianificaAppointmentDayOnDB `);
    pianificaAppointmentDayOnDB(dataAppuntamentiMillis);

  }, [])
  */


  // ------- FIN --------------  useEffect 


  // --------------------------------- INI -----------------------------------  const VIEW 

  const ViewCardCellaRisorse = ({ risorsa }) => {
    return (
      <Card 
        style={
          { backgroundColor: '#CCE5FF', height:70, width:170, border: "1px solid lightblue" }
        } 
        sx={
          { minWidth: 200 }
        }
        >
        <CardContent>
          <Typography variant="h6" component="div">
            {risorsa}
          </Typography>
          <Typography sx={{ color: 'text.secondary', fontSize: 14, mb: 0.1 }}>
             
          </Typography>
        </CardContent>
      </Card>
    );
  };

  const ViewCardCellaIntersezione = ({ dataGiorno }) => {
    return (
      <Card style={
            { backgroundColor: '#CCE5FF', height:70, width:100, border: "1px solid lightblue"}
          } 
        sx={
          { minWidth: 80 }
        } 
      >
        <CardContent>
          {/* 
          <Typography sx={{ color: 'text.secondary', fontSize: 1, mb: 0.1 }}>
            &nbsp;
          </Typography>
          */}
          <Typography sx={{ color: 'text.secondary', fontSize: 10, mb: 0.1, textAlign: 'center'}}>
            <b>{daysNomeEsteso[dataGiorno.day()]+' '+dataGiorno.format('DD')}</b>
          </Typography>
          <Typography sx={{ color: 'text.secondary', fontSize: 10, mb: 0.1, textAlign: 'center'}}>
            {monthsNomeEsteso[dataGiorno.month()]+' '+dataGiorno.format('YYYY')}
          </Typography>
        </CardContent>
      </Card>
    );

  };

  const ViewCardCellaOrario = ({ hour, row }) => {
    return (
      <Card style={
          { backgroundColor: '#CCE5FF', height:altezza_cella_appuntamento1_px, width:100, border: "1px solid lightblue"}
        } 
        sx={
          { minWidth: 80 }
        } 
      >
        <CardContent>
          <Typography variant="h6" component="div" sx={{ color: 'text.secondary', fontSize: 18, mb: 1, textAlign: "center" }}>
          {hour}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  const ViewCardCellaAppuntamento = ({ ora, cliente , categoria , servizio , durata, colore, sito , owner, bookingData, rigaOra, colonnaRisorsa }) => {

    let colorBackground=colore;     
    let durataVisualizzata=durata+" min";
    let borderStyle="3px outset lightblue"

    if (cliente.trim()==="") {
      colorBackground=colorBackground_cella_senzaAppuntamento;
      durataVisualizzata="";
    }
    cliente=cliente.substring(0,18);
    categoria=categoria.substring(0,16);
    servizio=servizio.substring(0, 8);
    if (bookingData != null && bookingData.length>0) {
      borderStyle="4px outset #1136a0";     
    }  

    return (
      <Card 
        onClick={(event) => {
          clickCellaAppuntamento(event, rigaOra, colonnaRisorsa, cliente, categoria, servizio, sito)
        }}
        style={
          { backgroundColor: colorBackground, height: altezza_cella_appuntamento1_px, width:170, padding: 0, margin:0, border: borderStyle,
            // borderTop: "3px outset lightblue", borderBottom: "3px outset lightblue",
            // borderLeft: "3px outset lightblue", borderRight: "3px outset lightblue"
          }
        } 
        sx={
          { minWidth: 200 }          
        }
      >

        <CardActions style={{ border: "none", padding:4 }}>
          <CardContent 
            style={
              { backgroundColor: colorBackground, height:altezza_cella_appuntamento_px, width:170, my:0, mt:0 , md:0, pt:0, margin:0, padding:0
              }
            } 
          >

            <TableContainer              
              style={
                { backgroundColor: colorBackground, height:altezza_cella_appuntamento_px, width:170, my:0, mt:0 , md:0, pt:0, margin:0, padding:0, border: "none",
                  //backgroundImage: linear-gradient("to right",  rgba(0, 0, 0, 0), rgba(0, 0, 100, 0), rgba(0,100, 0, 0),),
                   //maskImage: linear-gradient("to bottom", rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
                }
              } 
            >
              <Table sx={{ minWidth: 160 }} size="small" style={{ border: "none" }}>   
                <TableBody>

                  <TableRow style={{ borderBottom: "none" }}>
                    <TableCell component="th" scope="row" style={{ border: "none", padding:0 }}>
                      <Typography variant="h4" sx={{ color: 'rgb(51 81 255)', fontSize: 14, my:-3, mt:0, mb: 0 }}>              
                        {cliente} 
                      </Typography>
                    </TableCell>
                    <TableCell align="right" style={{ border: "none", padding:0 }}>
                      <Typography variant="h4" sx={{ color: 'rgb(140 140 140)', fontSize: 12, my:-3, mt:0, mb: 0 }}>              
                        {durataVisualizzata} 
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow style={{ borderBottom: "none" }}>
                    <TableCell component="th" scope="row" style={{ border: "none", padding:0 }}>
                      <Typography variant="h4" sx={{ color: 'rgb(44 44 44)', fontSize: 12, my:-3, mt:0, mb: 0 }}>              
                        {categoria} 
                      </Typography>
                    </TableCell>
                    <TableCell align="right" style={{ border: "none", padding:0 }}>
                      <Typography variant="h4" sx={{ color: 'rgb(44 44 44)', fontSize: 12, my:-3, mt:0, mb: 0 }}>              
                        {servizio} 
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow style={{ borderBottom: "none" }}>
                    <TableCell component="th" scope="row" style={{ border: "none", padding:0 }}>
                      <Typography variant="h4" sx={{ color: 'rgb(180 152 58)', fontSize: 12, my:-3, mt:0, mb: 0 }}>              
                        {sito} 
                      </Typography>
                    </TableCell>
                    <TableCell align="right" style={{ border: "none", padding:0 }}>
                      <Typography variant="h4" sx={{ color: 'rgb(51 81 255)', fontSize: 12, my:-3, mt:0, mb: 0 }}>              
                      </Typography>
                    </TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </TableContainer>

          </CardContent>
        </CardActions> 
      </Card>
    )
  };

  const ViewCardCellaInizioAppuntamento = ({ ora, cliente , categoria , servizio , durata, colore, sito , owner, rigaOra, colonnaRisorsa }) => {
    
    let colorBackground=colore;     
    let durataVisualizzata=durata+" min";
    if (cliente.trim()==="") {
      colorBackground=colorBackground_cella_senzaAppuntamento;
      durataVisualizzata="";
    }
    cliente=cliente.substring(0,18);
    categoria=categoria.substring(0,16);
    servizio=servizio.substring(0, 8);

    return (
      <Card 
        onClick={(event) => {
          clickCellaAppuntamento(event, rigaOra, colonnaRisorsa, cliente, categoria, servizio, sito)
        }}
        style={
          { backgroundColor: colorBackground, height:altezza_cella_appuntamento1_px, width:170, padding: 0, margin:0, 
            borderTop: "3px outset lightblue", 
            borderLeft: "3px outset lightblue", borderRight: "3px outset lightblue"
          }
        } 
        sx={
          { minWidth: 200 }          
        }
      >
        <CardActions>
          <CardContent 
            style={
              { backgroundColor: colorBackground, height:altezza_cella_appuntamento_px, width:170, my:0, mt:0 , md:0, pt:0, margin:0, padding:0}
            } 
          >

            <TableContainer              
              style={
                { backgroundColor: colorBackground, height:altezza_cella_appuntamento_px, width:170, my:0, mt:0 , md:0, pt:0, margin:0, padding:0, border: "none"}
              } 
            >
              <Table sx={{ minWidth: 160 }} size="small" style={{ border: "none" }}>   
                <TableBody>

                  <TableRow style={{ borderBottom: "none" }}>
                    <TableCell component="th" scope="row" style={{ border: "none", padding:0 }}>
                      <Typography variant="h4" sx={{ color: 'rgb(51 81 255)', fontSize: 14, my:-3, mt:0, mb: 0 }}>              
                        {cliente} 
                      </Typography>
                    </TableCell>
                    <TableCell align="right" style={{ border: "none", padding:0 }}>
                      <Typography variant="h4" sx={{ color: 'rgb(140 140 140)', fontSize: 12, my:-3, mt:0, mb: 0 }}>              
                        {durataVisualizzata} 
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow style={{ borderBottom: "none" }}>
                    <TableCell component="th" scope="row" style={{ border: "none", padding:0 }}>
                      <Typography variant="h4" sx={{ color: 'rgb(44 44 44)', fontSize: 12, my:-3, mt:0, mb: 0 }}>              
                        {categoria} 
                      </Typography>
                    </TableCell>
                    <TableCell align="right" style={{ border: "none", padding:0 }}>
                      <Typography variant="h4" sx={{ color: 'rgb(44 44 44)', fontSize: 12, my:-3, mt:0, mb: 0 }}>              
                        {servizio} 
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow style={{ borderBottom: "none" }}>
                    <TableCell component="th" scope="row" style={{ border: "none", padding:0 }}>
                      <Typography variant="h4" sx={{ color: 'rgb(180 152 58)', fontSize: 12, my:-3, mt:0, mb: 0 }}>              
                        {sito} 
                      </Typography>
                    </TableCell>
                    <TableCell align="right" style={{ border: "none", padding:0 }}>
                      <Typography variant="h4" sx={{ color: 'rgb(51 81 255)', fontSize: 12, my:-3, mt:0, mb: 0 }}>              
                      </Typography>
                    </TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </TableContainer>

          </CardContent>
        </CardActions> 
      </Card>
    )
  };

  const ViewCardCellaIntermediaAppuntamento = ({ ora, cliente , categoria , servizio , durata, colore, sito , owner, rigaOra, colonnaRisorsa }) => {

     //let colorBackground="#FCE8B7";     
     let colorBackground=colore;     
     let durataVisualizzata=durata+" min";
     if (cliente.trim()==="") {
       colorBackground=colorBackground_cella_senzaAppuntamento;
       durataVisualizzata="";
     }

    return (
      <Card 
        onClick={(event) => {
          clickCellaAppuntamento(event, rigaOra, colonnaRisorsa, cliente, categoria, servizio, sito)
        }}
        style={
          { backgroundColor: colorBackground, height:altezza_cella_appuntamento1_px, width:170, padding: 0, margin:0, 
            borderLeft: "3px outset lightblue", borderRight: "3px outset lightblue"
          }
        } 
        sx={
          { minWidth: 200 }          
        }
      >
        <CardActions>
          <CardContent 
            style={
              { backgroundColor: colorBackground, height:altezza_cella_appuntamento_px, width:170, my:0, mt:0 , md:0, pt:0, margin:0, padding:0}
            } 
          >
            <Typography variant="h4" sx={{ color: 'rgb(51 81 255)', fontSize: 16, my:-3, mt:0, mb: 0 }}>
              
            </Typography>
            </CardContent>
        </CardActions> 
      </Card>
    )
  };

  const ViewCardCellaFineAppuntamento = ({ ora, cliente , categoria , servizio , durata, colore, sito , owner, rigaOra, colonnaRisorsa }) => {
 
      //let colorBackground="#FCE8B7";     
      let colorBackground=colore;     
      let durataVisualizzata=durata+" min";
      if (cliente.trim()==="") {
        colorBackground=colorBackground_cella_senzaAppuntamento;
        durataVisualizzata="";
      }
 
    return (
      <Card 
        onClick={(event) => {
          clickCellaAppuntamento(event, rigaOra, colonnaRisorsa, cliente, categoria, servizio, sito)
        }}
        style={
          { backgroundColor: colorBackground, height:altezza_cella_appuntamento1_px, width:170, padding: 0, margin:0, 
            borderBottom: "3px outset lightblue",
            borderLeft: "3px outset lightblue", borderRight: "3px outset lightblue"
          }
        } 
        sx={
          { minWidth: 200 }          
        }
      >
        <CardActions>
          <CardContent 
            style={
              { backgroundColor: colorBackground, height:altezza_cella_appuntamento_px, width:170, my:0, mt:0 , md:0, pt:0, margin:0, padding:0}
            } 
          >
            <Typography variant="h4" sx={{ color: 'rgb(51 81 255)', fontSize: 16, my:-3, mt:0, mb: 0 }}>
              
            </Typography>
            </CardContent>
        </CardActions> 
      </Card>
    )
  };

// --------------------------------- FIN -----------------------------------  const VIEW 


  return (
    <Container fluid className="px-0">

      <Row
        g-0
        className="pt-2 pt-md-5 w-100 px-4 px-xl-0 position-relative"
      >
        
         
          <Form.Group className="mb-3" controlId="validationCustom01">

          <Row className="justify-content-md-center">
              <Col md={3} 
                style={{
                  textAlign: "right",
                 }}
              >
                <em><b><h3>Appuntamenti</h3></b></em>
              </Col>

              <Col md={3}
                  style={{
                      width: "180px",
                    }}
              >     
                <LocalizationProvider dateAdapter={AdapterDayjs} locale={itIT} adapterLocale="it">
                  <DatePicker 
                    defaultValue={dataAppuntamentiMillis}
                    value={dataAppuntamentiMillis}
                    onChange={setDataAppuntamentiMillis}
                    inputFormat="dd-MM-yyyy"
                    format="DD/MM/YYYY"
                  />
                </LocalizationProvider>
              </Col>

              <Col md={3}>     
                <Button variant="success"
                  onClick={(e) => {pianificaAppointmentDayOnDB(e,dataAppuntamentiMillis) }}
                >
                  Pianifica
                </Button>
              </Col>
            </Row >

          </Form.Group> 

    
        {isOpenTabAppuntamento && (
          <ModalAppuntamento
            id="modal"
            isOpenTabAppuntamento={isOpenTabAppuntamento}
            closeModal={closeModalAppuntamento}
            modalAction={modalActionAppuntamento}
            handleMouseDown={handleMouseDown}

            giorno={modalAppuntamentoGiorno}
            ora={modalAppuntamentoOra}
            riga={modalAppuntamentoRiga}
            colonna={modalAppuntamentoColonna}
            titoloRisorsa={modalAppuntamentoTitoloRisorsa}
            cliente={modalAppuntamentoCliente}
            categoria={modalAppuntamentoCategoria}
            prodotto={modalAppuntamentoProdotto}
            sito={modalAppuntamentoSito}
            durata={modalAppuntamentoDurata}
            colore={modalAppuntamentoColore}
            owner={modalAppuntamentoOwner}
            giornoAgenda={agenda}
            bookingData={modalAppuntamentoBookingDateTime}
            bookingPrice={modalAppuntamentoBookingPrice}
            bookingCard={modalAppuntamentoBookingCard}
          >
          </ModalAppuntamento>
        )}

    </Row>
        
    <Row
      g-0
      className="pt-2 pt-md-2 w-100 px-4 px-xl-0 position-relative"
    >


      <Grid container className="grid-container">

            <Grid item xs={5}>
              <ViewCardCellaIntersezione dataGiorno={dataAppuntamentiMillis}  />
            </Grid>

            { agenda.columnResource?.map((res) => {
                                return (
                                  <Grid item xs={5}>
                                    <ViewCardCellaRisorse risorsa={res.resourceTitle} />
                                  </Grid>
                                  );
            })}


        {/* 

              <ViewCardCellaIntersezione dataGiorno="2024-12-05"  />
        modalAppuntamentoGiorno

        <Grid
          item
          xs={6}
          className={"grid-column"}
          style={{ backgroundColor: "pink" }}
        >
        */}

        <Grid
          item
          xs={6}
          className={"grid-column"}
          style={{ backgroundColor: "lightcyan" }}
        >


          { agendaOraAppuntamenti.map((ora, indexOra) => {
            return (
              <Grid container>

                <Grid item xs={5}>
                  <ViewCardCellaOrario hour={ora} row="3"  />
                </Grid>

                { agenda.columnResource?.map((res,indexRisorsa) => {
                    //console.log("ciclo columnResource: indexRisorsa =",indexRisorsa)                
                    //console.log("ciclo columnResource: indexOra =",indexOra)                
                    //console.log("ciclo columnResource: hour 1l =",agenda.resource0001appointments[indexOra].hour)                
                    //console.log("ciclo columnResource: hour 2l =",agenda.resource0001appointments[indexOra].appointment.owner)                
                    
                    // --------------------------------- resource0001
                    //
                    // cella unica x l'appuntamento
                    
                    //console.log("UNICA indexRisorsa =",indexRisorsa)                
                    //console.log("UNICA indexOra =",indexOra)                
                    //console.log("UNICA ora =",agenda.resource0001appointments[indexOra].hour)
                    //console.log("UNICA length =",agenda.resource0001appointments.length-1)    
                    //console.log("UNICA <",10<12)    
                    //console.log("UNICA  ",indexOra==agenda.resource0001appointments.lenght-1)                
                    //console.log("UNICA  ",(indexOra===0 || !(agenda.resource0001appointments[indexOra].appointment.owner===agenda.resource0001appointments[indexOra-1].appointment.owner)))
                    
                    if ((indexRisorsa===0) 
                    && (agenda.resource0001appointments[indexOra].hour===agenda.resource0001appointments[indexOra].appointment.owner)
                    && ((indexOra<agenda.resource0001appointments.length-1 && !(agenda.resource0001appointments[indexOra].appointment.owner===agenda.resource0001appointments[indexOra+1].appointment.owner))
                        || indexOra===agenda.resource0001appointments.length-1) 
                    && (indexOra===0 || !(agenda.resource0001appointments[indexOra].appointment.owner===agenda.resource0001appointments[indexOra-1].appointment.owner)))
                    {
                      return (
                        <Grid item xs={5}>
                              <ViewCardCellaAppuntamento ora={agenda.resource0001appointments[indexOra].hour} cliente={agenda.resource0001appointments[indexOra].appointment.customer} categoria={agenda.resource0001appointments[indexOra].appointment.category} servizio={agenda.resource0001appointments[indexOra].appointment.product} durata={agenda.resource0001appointments[indexOra].appointment.durationMins} colore={agenda.resource0001appointments[indexOra].appointment.color} sito={agenda.resource0001appointments[indexOra].appointment.site} owner={agenda.resource0001appointments[indexOra].appointment.owner} bookingData={agenda.resource0001appointments[indexOra].appointment.bookingData} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                        </Grid>
                      );
                    }

                    // cella di inizio appuntamento
                    if ((indexRisorsa===0) 
                    && (agenda.resource0001appointments[indexOra].hour===agenda.resource0001appointments[indexOra].appointment.owner)
                    && (agenda.resource0001appointments[indexOra].appointment.owner===agenda.resource0001appointments[indexOra+1].appointment.owner)
                    && !(agenda.resource0001appointments[indexOra].appointment.owner===agenda.resource0001appointments[indexOra-1].appointment.owner))
                    {
                      //console.log("INIZIO indexRisorsa =",indexRisorsa)                
                      //console.log("INIZIO indexOra =",indexOra)                
                      //console.log("INIZIO  ",agenda.resource0001appointments[indexOra].appointment.owner===agenda.resource0001appointments[indexOra+1].appointment.owner)                
                      //console.log("INIZIO  ",!(agenda.resource0001appointments[indexOra].appointment.owner===agenda.resource0001appointments[indexOra-1].appointment.owner))
                      
                      return (
                        <Grid item xs={5}>
                              <ViewCardCellaInizioAppuntamento ora={agenda.resource0001appointments[indexOra].hour} cliente={agenda.resource0001appointments[indexOra].appointment.customer} categoria={agenda.resource0001appointments[indexOra].appointment.category} servizio={agenda.resource0001appointments[indexOra].appointment.product} durata={agenda.resource0001appointments[indexOra].appointment.durationMins} colore={agenda.resource0001appointments[indexOra].appointment.color} sito={agenda.resource0001appointments[indexOra].appointment.site} owner={agenda.resource0001appointments[indexOra].appointment.owner} bookingData={agenda.resource0002appointments[indexOra].appointment.bookingData} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                        </Grid>
                      );
                    }

                    // cella intermedia appuntamento
                    if ((indexRisorsa===0) 
                    && !(agenda.resource0001appointments[indexOra].hour===agenda.resource0001appointments[indexOra].appointment.owner)
                    && ((indexOra>0) && (agenda.resource0001appointments[indexOra].appointment.owner===agenda.resource0001appointments[indexOra-1].appointment.owner))
                    && (agenda.resource0001appointments[indexOra].appointment.owner===agenda.resource0001appointments[indexOra+1].appointment.owner))
                    {
                      return (
                        <Grid item xs={5}>
                              <ViewCardCellaIntermediaAppuntamento ora={agenda.resource0001appointments[indexOra].hour} cliente={agenda.resource0001appointments[indexOra].appointment.customer} categoria={agenda.resource0001appointments[indexOra].appointment.category} servizio={agenda.resource0001appointments[indexOra].appointment.product} durata={agenda.resource0001appointments[indexOra].appointment.durationMins} colore={agenda.resource0001appointments[indexOra].appointment.color} sito={agenda.resource0001appointments[indexOra].appointment.site} owner={agenda.resource0001appointments[indexOra].appointment.owner} bookingData={agenda.resource0003appointments[indexOra].appointment.bookingData} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                        </Grid>
                      );
                    }

                    if ((indexRisorsa===0) && (indexOra=5))
                    {
                      //console.log("FINE indexRisorsa =",indexRisorsa)                
                      //console.log("FINE indexOra =",indexOra)                
                      //console.log("FINE length =",agenda.resource0001appointments.length-1)    
                      //console.log("FINE 1a cond ",!(agenda.resource0001appointments[indexOra].hour===agenda.resource0001appointments[indexOra].appointment.owner))                
                      //console.log("FINE 2b cond =",!(agenda.resource0001appointments[indexOra].appointment.owner===agenda.resource0001appointments[indexOra+1].appointment.owner))    
                      //console.log("FINE 3a cond ",((indexOra>0) && (agenda.resource0001appointments[indexOra].appointment.owner===agenda.resource0001appointments[indexOra-1].appointment.owner)))
                    }
       
                    // cella fine appuntamento
                    if ((indexRisorsa===0) 
                    && !(agenda.resource0001appointments[indexOra].hour===agenda.resource0001appointments[indexOra].appointment.owner)
                    && !(agenda.resource0001appointments[indexOra].appointment.owner===agenda.resource0001appointments[indexOra+1].appointment.owner)
                    && ((indexOra>0) && (agenda.resource0001appointments[indexOra].appointment.owner===agenda.resource0001appointments[indexOra-1].appointment.owner)))
                    {
                      return (
                        <Grid item xs={5}>
                              <ViewCardCellaFineAppuntamento ora={agenda.resource0001appointments[indexOra].hour} cliente={agenda.resource0001appointments[indexOra].appointment.customer} categoria={agenda.resource0001appointments[indexOra].appointment.category} servizio={agenda.resource0001appointments[indexOra].appointment.product} durata={agenda.resource0001appointments[indexOra].appointment.durationMins} colore={agenda.resource0001appointments[indexOra].appointment.color} sito={agenda.resource0001appointments[indexOra].appointment.site} owner={agenda.resource0001appointments[indexOra].appointment.owner} bookingData={agenda.resource0004appointments[indexOra].appointment.bookingData} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                        </Grid>
                      );
                    }

                    // --------------------------------- resource0002
                    //
                    // cella unica x l'appuntamento
                    if ((indexRisorsa===1) 
                    && (agenda.resource0002appointments[indexOra].hour===agenda.resource0002appointments[indexOra].appointment.owner)
                    && ((indexOra<agenda.resource0002appointments.length-1 && !(agenda.resource0002appointments[indexOra].appointment.owner===agenda.resource0002appointments[indexOra+1].appointment.owner))
                        || indexOra===agenda.resource0002appointments.length-1) 
                    && (indexOra===0 || !(agenda.resource0002appointments[indexOra].appointment.owner===agenda.resource0002appointments[indexOra-1].appointment.owner)))
                    {
                      return (
                        <Grid item xs={5}>
                              <ViewCardCellaAppuntamento ora={agenda.resource0002appointments[indexOra].hour} cliente={agenda.resource0002appointments[indexOra].appointment.customer} categoria={agenda.resource0002appointments[indexOra].appointment.category} servizio={agenda.resource0002appointments[indexOra].appointment.product} durata={agenda.resource0002appointments[indexOra].appointment.durationMins} colore={agenda.resource0002appointments[indexOra].appointment.color} sito={agenda.resource0002appointments[indexOra].appointment.site} owner={agenda.resource0002appointments[indexOra].appointment.owner} bookingData={agenda.resource0005appointments[indexOra].appointment.bookingData} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                        </Grid>
                      );
                    }
                    // cella di inizio appuntamento
                    if ((indexRisorsa===1) 
                    && (agenda.resource0002appointments[indexOra].hour===agenda.resource0002appointments[indexOra].appointment.owner)
                    && (agenda.resource0002appointments[indexOra].appointment.owner===agenda.resource0002appointments[indexOra+1].appointment.owner)
                    && !(agenda.resource0002appointments[indexOra].appointment.owner===agenda.resource0002appointments[indexOra-1].appointment.owner))
                    {
                      return (
                        <Grid item xs={5}>
                              <ViewCardCellaInizioAppuntamento ora={agenda.resource0002appointments[indexOra].hour} cliente={agenda.resource0002appointments[indexOra].appointment.customer} categoria={agenda.resource0002appointments[indexOra].appointment.category} servizio={agenda.resource0002appointments[indexOra].appointment.product} durata={agenda.resource0002appointments[indexOra].appointment.durationMins} colore={agenda.resource0002appointments[indexOra].appointment.color} sito={agenda.resource0002appointments[indexOra].appointment.site} owner={agenda.resource0002appointments[indexOra].appointment.owner} bookingData={agenda.resource0006appointments[indexOra].appointment.bookingData} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                        </Grid>
                      );
                    }
                    // cella intermedia appuntamento
                    if ((indexRisorsa===1) 
                    && !(agenda.resource0002appointments[indexOra].hour===agenda.resource0002appointments[indexOra].appointment.owner)
                    && ((indexOra>0) && (agenda.resource0002appointments[indexOra].appointment.owner===agenda.resource0002appointments[indexOra-1].appointment.owner))
                    && (agenda.resource0002appointments[indexOra].appointment.owner===agenda.resource0002appointments[indexOra+1].appointment.owner))
                    {
                      return (
                        <Grid item xs={5}>
                              <ViewCardCellaIntermediaAppuntamento ora={agenda.resource0002appointments[indexOra].hour} cliente={agenda.resource0002appointments[indexOra].appointment.customer} categoria={agenda.resource0002appointments[indexOra].appointment.category} servizio={agenda.resource0002appointments[indexOra].appointment.product} durata={agenda.resource0002appointments[indexOra].appointment.durationMins} colore={agenda.resource0002appointments[indexOra].appointment.color} sito={agenda.resource0002appointments[indexOra].appointment.site} owner={agenda.resource0002appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                        </Grid>
                      );
                    }
                    // cella fine appuntamento
                    if ((indexRisorsa===1) 
                    && !(agenda.resource0002appointments[indexOra].hour===agenda.resource0002appointments[indexOra].appointment.owner)
                    && !(agenda.resource0002appointments[indexOra].appointment.owner===agenda.resource0002appointments[indexOra+1].appointment.owner)
                    && ((indexOra>0) && (agenda.resource0002appointments[indexOra].appointment.owner===agenda.resource0002appointments[indexOra-1].appointment.owner)))
                    {
                      return (
                        <Grid item xs={5}>
                              <ViewCardCellaFineAppuntamento ora={agenda.resource0002appointments[indexOra].hour} cliente={agenda.resource0002appointments[indexOra].appointment.customer} categoria={agenda.resource0002appointments[indexOra].appointment.category} servizio={agenda.resource0002appointments[indexOra].appointment.product} durata={agenda.resource0002appointments[indexOra].appointment.durationMins} colore={agenda.resource0002appointments[indexOra].appointment.color} sito={agenda.resource0002appointments[indexOra].appointment.site} owner={agenda.resource0002appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                        </Grid>
                      );
                    }
        
                    // --------------------------------- resource0003
                    //
                    // cella unica x l'appuntamento
                    if ((indexRisorsa===2) 
                    && (agenda.resource0003appointments[indexOra].hour===agenda.resource0003appointments[indexOra].appointment.owner)
                    && ((indexOra<agenda.resource0003appointments.length-1 && !(agenda.resource0003appointments[indexOra].appointment.owner===agenda.resource0003appointments[indexOra+1].appointment.owner))
                        || indexOra===agenda.resource0003appointments.length-1) 
                    && (indexOra===0 || !(agenda.resource0003appointments[indexOra].appointment.owner===agenda.resource0003appointments[indexOra-1].appointment.owner)))
                    {
                      return (
                        <Grid item xs={5}>
                              <ViewCardCellaAppuntamento ora={agenda.resource0003appointments[indexOra].hour} cliente={agenda.resource0003appointments[indexOra].appointment.customer} categoria={agenda.resource0003appointments[indexOra].appointment.category} servizio={agenda.resource0003appointments[indexOra].appointment.product} durata={agenda.resource0003appointments[indexOra].appointment.durationMins} colore={agenda.resource0003appointments[indexOra].appointment.color} sito={agenda.resource0003appointments[indexOra].appointment.site} owner={agenda.resource0003appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                        </Grid>
                      );
                    }
                    // cella di inizio appuntamento
                    if ((indexRisorsa===2) 
                    && (agenda.resource0003appointments[indexOra].hour===agenda.resource0003appointments[indexOra].appointment.owner)
                    && (agenda.resource0003appointments[indexOra].appointment.owner===agenda.resource0003appointments[indexOra+1].appointment.owner)
                    && !(agenda.resource0003appointments[indexOra].appointment.owner===agenda.resource0003appointments[indexOra-1].appointment.owner))
                    {
                      return (
                        <Grid item xs={5}>
                              <ViewCardCellaInizioAppuntamento ora={agenda.resource0003appointments[indexOra].hour} cliente={agenda.resource0003appointments[indexOra].appointment.customer} categoria={agenda.resource0003appointments[indexOra].appointment.category} servizio={agenda.resource0003appointments[indexOra].appointment.product} durata={agenda.resource0003appointments[indexOra].appointment.durationMins} colore={agenda.resource0003appointments[indexOra].appointment.color} sito={agenda.resource0003appointments[indexOra].appointment.site} owner={agenda.resource0003appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                        </Grid>
                      );
                    }
                    // cella intermedia appuntamento
                    if ((indexRisorsa===2) 
                    && !(agenda.resource0003appointments[indexOra].hour===agenda.resource0003appointments[indexOra].appointment.owner)
                    && ((indexOra>0) && (agenda.resource0003appointments[indexOra].appointment.owner===agenda.resource0003appointments[indexOra-1].appointment.owner))
                    && (agenda.resource0003appointments[indexOra].appointment.owner===agenda.resource0003appointments[indexOra+1].appointment.owner))
                    {
                      return (
                        <Grid item xs={5}>
                              <ViewCardCellaIntermediaAppuntamento ora={agenda.resource0003appointments[indexOra].hour} cliente={agenda.resource0003appointments[indexOra].appointment.customer} categoria={agenda.resource0003appointments[indexOra].appointment.category} servizio={agenda.resource0003appointments[indexOra].appointment.product} durata={agenda.resource0003appointments[indexOra].appointment.durationMins} colore={agenda.resource0003appointments[indexOra].appointment.color} sito={agenda.resource0003appointments[indexOra].appointment.site} owner={agenda.resource0003appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                        </Grid>
                      );
                    }
                    // cella fine appuntamento
                    if ((indexRisorsa===2) 
                    && !(agenda.resource0003appointments[indexOra].hour===agenda.resource0003appointments[indexOra].appointment.owner)
                    && !(agenda.resource0003appointments[indexOra].appointment.owner===agenda.resource0003appointments[indexOra+1].appointment.owner)
                    && ((indexOra>0) && (agenda.resource0003appointments[indexOra].appointment.owner===agenda.resource0003appointments[indexOra-1].appointment.owner)))
                    {
                      return (
                        <Grid item xs={5}>
                              <ViewCardCellaFineAppuntamento ora={agenda.resource0003appointments[indexOra].hour} cliente={agenda.resource0003appointments[indexOra].appointment.customer} categoria={agenda.resource0003appointments[indexOra].appointment.category} servizio={agenda.resource0003appointments[indexOra].appointment.product} durata={agenda.resource0003appointments[indexOra].appointment.durationMins} colore={agenda.resource0003appointments[indexOra].appointment.color} sito={agenda.resource0003appointments[indexOra].appointment.site} owner={agenda.resource0003appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                        </Grid>
                      );
                    }
    
                    // --------------------------------- resource0004
                    //
                    // cella unica x l'appuntamento
                    if ((indexRisorsa===3) 
                      && (agenda.resource0004appointments[indexOra].hour===agenda.resource0004appointments[indexOra].appointment.owner)
                      && ((indexOra<agenda.resource0004appointments.length-1 && !(agenda.resource0004appointments[indexOra].appointment.owner===agenda.resource0004appointments[indexOra+1].appointment.owner))
                          || indexOra===agenda.resource0004appointments.length-1) 
                      && (indexOra===0 || !(agenda.resource0004appointments[indexOra].appointment.owner===agenda.resource0004appointments[indexOra-1].appointment.owner)))
                      {
                        return (
                          <Grid item xs={5}>
                                <ViewCardCellaAppuntamento ora={agenda.resource0004appointments[indexOra].hour} cliente={agenda.resource0004appointments[indexOra].appointment.customer} categoria={agenda.resource0004appointments[indexOra].appointment.category} servizio={agenda.resource0004appointments[indexOra].appointment.product} durata={agenda.resource0004appointments[indexOra].appointment.durationMins} colore={agenda.resource0004appointments[indexOra].appointment.color} sito={agenda.resource0004appointments[indexOra].appointment.site} owner={agenda.resource0004appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                          </Grid>
                        );
                      }
                      // cella di inizio appuntamento
                      if ((indexRisorsa===3) 
                      && (agenda.resource0004appointments[indexOra].hour===agenda.resource0004appointments[indexOra].appointment.owner)
                      && (agenda.resource0004appointments[indexOra].appointment.owner===agenda.resource0004appointments[indexOra+1].appointment.owner)
                      && !(agenda.resource0004appointments[indexOra].appointment.owner===agenda.resource0004appointments[indexOra-1].appointment.owner))
                      {
                        return (
                          <Grid item xs={5}>
                                <ViewCardCellaInizioAppuntamento ora={agenda.resource0004appointments[indexOra].hour} cliente={agenda.resource0004appointments[indexOra].appointment.customer} categoria={agenda.resource0004appointments[indexOra].appointment.category} servizio={agenda.resource0004appointments[indexOra].appointment.product} durata={agenda.resource0004appointments[indexOra].appointment.durationMins} colore={agenda.resource0004appointments[indexOra].appointment.color} sito={agenda.resource0004appointments[indexOra].appointment.site} owner={agenda.resource0004appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                          </Grid>
                        );
                      }
                      // cella intermedia appuntamento
                      if ((indexRisorsa===3) 
                      && !(agenda.resource0004appointments[indexOra].hour===agenda.resource0004appointments[indexOra].appointment.owner)
                      && ((indexOra>0) && (agenda.resource0004appointments[indexOra].appointment.owner===agenda.resource0004appointments[indexOra-1].appointment.owner))
                      && (agenda.resource0004appointments[indexOra].appointment.owner===agenda.resource0004appointments[indexOra+1].appointment.owner))
                      {
                        return (
                          <Grid item xs={5}>
                                <ViewCardCellaIntermediaAppuntamento ora={agenda.resource0004appointments[indexOra].hour} cliente={agenda.resource0004appointments[indexOra].appointment.customer} categoria={agenda.resource0004appointments[indexOra].appointment.category} servizio={agenda.resource0004appointments[indexOra].appointment.product} durata={agenda.resource0004appointments[indexOra].appointment.durationMins} colore={agenda.resource0004appointments[indexOra].appointment.color} sito={agenda.resource0004appointments[indexOra].appointment.site} owner={agenda.resource0004appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                          </Grid>
                        );
                      }
                      // cella fine appuntamento
                      if ((indexRisorsa===3) 
                      && !(agenda.resource0004appointments[indexOra].hour===agenda.resource0004appointments[indexOra].appointment.owner)
                      && !(agenda.resource0004appointments[indexOra].appointment.owner===agenda.resource0004appointments[indexOra+1].appointment.owner)
                      && ((indexOra>0) && (agenda.resource0004appointments[indexOra].appointment.owner===agenda.resource0004appointments[indexOra-1].appointment.owner)))
                      {
                        return (
                          <Grid item xs={5}>
                                <ViewCardCellaFineAppuntamento ora={agenda.resource0004appointments[indexOra].hour} cliente={agenda.resource0004appointments[indexOra].appointment.customer} categoria={agenda.resource0004appointments[indexOra].appointment.category} servizio={agenda.resource0004appointments[indexOra].appointment.product} durata={agenda.resource0004appointments[indexOra].appointment.durationMins} colore={agenda.resource0004appointments[indexOra].appointment.color} sito={agenda.resource0004appointments[indexOra].appointment.site} owner={agenda.resource0004appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                          </Grid>
                        );
                      }
  
                    // --------------------------------- resource0005
                    //
                    // cella unica x l'appuntamento
                    if ((indexRisorsa===4) 
                      && (agenda.resource0005appointments[indexOra].hour===agenda.resource0005appointments[indexOra].appointment.owner)
                      && ((indexOra<agenda.resource0005appointments.length-1 && !(agenda.resource0005appointments[indexOra].appointment.owner===agenda.resource0005appointments[indexOra+1].appointment.owner))
                          || indexOra===agenda.resource0005appointments.length-1) 
                      && (indexOra===0 || !(agenda.resource0005appointments[indexOra].appointment.owner===agenda.resource0005appointments[indexOra-1].appointment.owner)))
                      {
                        return (
                          <Grid item xs={5}>
                                <ViewCardCellaAppuntamento ora={agenda.resource0005appointments[indexOra].hour} cliente={agenda.resource0005appointments[indexOra].appointment.customer} categoria={agenda.resource0005appointments[indexOra].appointment.category} servizio={agenda.resource0005appointments[indexOra].appointment.product} durata={agenda.resource0005appointments[indexOra].appointment.durationMins} colore={agenda.resource0005appointments[indexOra].appointment.color} sito={agenda.resource0005appointments[indexOra].appointment.site} owner={agenda.resource0005appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                          </Grid>
                        );
                      }
                      // cella di inizio appuntamento
                      if ((indexRisorsa===4) 
                      && (agenda.resource0005appointments[indexOra].hour===agenda.resource0005appointments[indexOra].appointment.owner)
                      && (agenda.resource0005appointments[indexOra].appointment.owner===agenda.resource0005appointments[indexOra+1].appointment.owner)
                      && !(agenda.resource0005appointments[indexOra].appointment.owner===agenda.resource0005appointments[indexOra-1].appointment.owner))
                      {
                        return (
                          <Grid item xs={5}>
                                <ViewCardCellaInizioAppuntamento ora={agenda.resource0005appointments[indexOra].hour} cliente={agenda.resource0005appointments[indexOra].appointment.customer} categoria={agenda.resource0005appointments[indexOra].appointment.category} servizio={agenda.resource0005appointments[indexOra].appointment.product} durata={agenda.resource0005appointments[indexOra].appointment.durationMins} colore={agenda.resource0005appointments[indexOra].appointment.color} sito={agenda.resource0005appointments[indexOra].appointment.site} owner={agenda.resource0005appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                          </Grid>
                        );
                      }
                      // cella intermedia appuntamento
                      if ((indexRisorsa===4) 
                      && !(agenda.resource0005appointments[indexOra].hour===agenda.resource0005appointments[indexOra].appointment.owner)
                      && ((indexOra>0) && (agenda.resource0005appointments[indexOra].appointment.owner===agenda.resource0005appointments[indexOra-1].appointment.owner))
                      && (agenda.resource0005appointments[indexOra].appointment.owner===agenda.resource0005appointments[indexOra+1].appointment.owner))
                      {
                        return (
                          <Grid item xs={5}>
                                <ViewCardCellaIntermediaAppuntamento ora={agenda.resource0005appointments[indexOra].hour} cliente={agenda.resource0005appointments[indexOra].appointment.customer} categoria={agenda.resource0005appointments[indexOra].appointment.category} servizio={agenda.resource0005appointments[indexOra].appointment.product} durata={agenda.resource0005appointments[indexOra].appointment.durationMins} colore={agenda.resource0005appointments[indexOra].appointment.color} sito={agenda.resource0005appointments[indexOra].appointment.site} owner={agenda.resource0005appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                          </Grid>
                        );
                      }
                      // cella fine appuntamento
                      if ((indexRisorsa===4) 
                      && !(agenda.resource0005appointments[indexOra].hour===agenda.resource0005appointments[indexOra].appointment.owner)
                      && !(agenda.resource0005appointments[indexOra].appointment.owner===agenda.resource0005appointments[indexOra+1].appointment.owner)
                      && ((indexOra>0) && (agenda.resource0005appointments[indexOra].appointment.owner===agenda.resource0005appointments[indexOra-1].appointment.owner)))
                      {
                        return (
                          <Grid item xs={5}>
                                <ViewCardCellaFineAppuntamento ora={agenda.resource0005appointments[indexOra].hour} cliente={agenda.resource0005appointments[indexOra].appointment.customer} categoria={agenda.resource0005appointments[indexOra].appointment.category} servizio={agenda.resource0005appointments[indexOra].appointment.product} durata={agenda.resource0005appointments[indexOra].appointment.durationMins} colore={agenda.resource0005appointments[indexOra].appointment.color} sito={agenda.resource0005appointments[indexOra].appointment.site} owner={agenda.resource0005appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                          </Grid>
                        );
                      }
  
                    // --------------------------------- resource0006
                    //
                    // cella unica x l'appuntamento
                    if ((indexRisorsa===5) 
                      && (agenda.resource0006appointments[indexOra].hour===agenda.resource0006appointments[indexOra].appointment.owner)
                      && ((indexOra<agenda.resource0006appointments.length-1 && !(agenda.resource0006appointments[indexOra].appointment.owner===agenda.resource0006appointments[indexOra+1].appointment.owner))
                          || indexOra===agenda.resource0006appointments.length-1) 
                      && (indexOra===0 || !(agenda.resource0006appointments[indexOra].appointment.owner===agenda.resource0006appointments[indexOra-1].appointment.owner)))
                      {
                        return (
                          <Grid item xs={5}>
                                <ViewCardCellaAppuntamento ora={agenda.resource0006appointments[indexOra].hour} cliente={agenda.resource0006appointments[indexOra].appointment.customer} categoria={agenda.resource0006appointments[indexOra].appointment.category} servizio={agenda.resource0006appointments[indexOra].appointment.product} durata={agenda.resource0006appointments[indexOra].appointment.durationMins} colore={agenda.resource0006appointments[indexOra].appointment.color} sito={agenda.resource0006appointments[indexOra].appointment.site} owner={agenda.resource0006appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                          </Grid>
                        );
                      }
                      // cella di inizio appuntamento
                      if ((indexRisorsa===5) 
                      && (agenda.resource0006appointments[indexOra].hour===agenda.resource0006appointments[indexOra].appointment.owner)
                      && (agenda.resource0006appointments[indexOra].appointment.owner===agenda.resource0006appointments[indexOra+1].appointment.owner)
                      && !(agenda.resource0006appointments[indexOra].appointment.owner===agenda.resource0006appointments[indexOra-1].appointment.owner))
                      {
                        return (
                          <Grid item xs={5}>
                                <ViewCardCellaInizioAppuntamento ora={agenda.resource0006appointments[indexOra].hour} cliente={agenda.resource0006appointments[indexOra].appointment.customer} categoria={agenda.resource0006appointments[indexOra].appointment.category} servizio={agenda.resource0006appointments[indexOra].appointment.product} durata={agenda.resource0006appointments[indexOra].appointment.durationMins} colore={agenda.resource0006appointments[indexOra].appointment.color} sito={agenda.resource0006appointments[indexOra].appointment.site} owner={agenda.resource0006appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                          </Grid>
                        );
                      }
                      // cella intermedia appuntamento
                      if ((indexRisorsa===5) 
                      && !(agenda.resource0006appointments[indexOra].hour===agenda.resource0006appointments[indexOra].appointment.owner)
                      && ((indexOra>0) && (agenda.resource0006appointments[indexOra].appointment.owner===agenda.resource0006appointments[indexOra-1].appointment.owner))
                      && (agenda.resource0006appointments[indexOra].appointment.owner===agenda.resource0006appointments[indexOra+1].appointment.owner))
                      {
                        return (
                          <Grid item xs={5}>
                                <ViewCardCellaIntermediaAppuntamento ora={agenda.resource0006appointments[indexOra].hour} cliente={agenda.resource0006appointments[indexOra].appointment.customer} categoria={agenda.resource0006appointments[indexOra].appointment.category} servizio={agenda.resource0006appointments[indexOra].appointment.product} durata={agenda.resource0006appointments[indexOra].appointment.durationMins} colore={agenda.resource0006appointments[indexOra].appointment.color} sito={agenda.resource0006appointments[indexOra].appointment.site} owner={agenda.resource0006appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                          </Grid>
                        );
                      }
                      // cella fine appuntamento
                      if ((indexRisorsa===5) 
                      && !(agenda.resource0006appointments[indexOra].hour===agenda.resource0006appointments[indexOra].appointment.owner)
                      && !(agenda.resource0006appointments[indexOra].appointment.owner===agenda.resource0006appointments[indexOra+1].appointment.owner)
                      && ((indexOra>0) && (agenda.resource0006appointments[indexOra].appointment.owner===agenda.resource0006appointments[indexOra-1].appointment.owner)))
                      {
                        return (
                          <Grid item xs={5}>
                                <ViewCardCellaFineAppuntamento ora={agenda.resource0006appointments[indexOra].hour} cliente={agenda.resource0006appointments[indexOra].appointment.customer} categoria={agenda.resource0006appointments[indexOra].appointment.category} servizio={agenda.resource0006appointments[indexOra].appointment.product} durata={agenda.resource0006appointments[indexOra].appointment.durationMins} colore={agenda.resource0006appointments[indexOra].appointment.color} sito={agenda.resource0006appointments[indexOra].appointment.site} owner={agenda.resource0006appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                          </Grid>
                        );
                      }

                }
                
                )}

              </Grid>              
              )           
          })}

          </Grid>

      </Grid>
    </Row>


    <Row className="mt-5 justify-content-md-center">
      <Col md={6}>
      </Col>     
    </Row>



  </Container>
  );
};

export default ProdottiPageComponent;
