import AdminPaymentsPageComponent from "./components/AdminPaymentsPageComponent";
import axios from "axios";

const fetchPayments = async (abctrl) => {
    const {data} = await axios.get("/api/payments", {
        signal: abctrl.signal,
    });
    return data
}

const deletePayment = async (userId) => {
    const { data } = await axios.delete(`/api/payments/${userId}`);
    return data
}

const AdminPaymentsPage = () => {
  return <AdminPaymentsPageComponent fetchPayments={fetchPayments} deletePayment={deletePayment} />;
};

export default AdminPaymentsPage;