import EditPaymentPageComponent from "./components/EditPaymentPageComponent";
import axios from "axios";

const fetchPayment = async (paymentId) => {
    const { data } = await axios.get(`/api/payments/${paymentId}`);
    return data;
}

const updatePaymentApiRequest = async (id, title, name, surname, telephone, email, address, code, color, image) => {
    const { data } = await axios.put(`/api/payments/${id}`, { title, name, surname, telephone, email, address, code, color, image });
    return data;
}

const AdminEditPaymentPage = () => {
  
  return <EditPaymentPageComponent updatePaymentApiRequest={updatePaymentApiRequest} fetchPayment={fetchPayment} />;
};


export default AdminEditPaymentPage;
