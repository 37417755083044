import {
  Row,
  Col,
  Container,
  Form,
  Button,
  CloseButton,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { changeCategory, setValuesForAttrFromDbSelectForm, setAttributesTableWrapper } from "./utils/utils";
import InputGroup from 'react-bootstrap/InputGroup';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Alert } from "react-bootstrap";
import Typography from '@mui/material/Typography';

const CreateProductPageComponent = ({
  createProductApiRequest,
  uploadImagesApiRequest,
  uploadImagesCloudinaryApiRequest,
  categories,
  reduxDispatch,
  newCategory,
  deleteCategory,
  saveAttributeToCatDoc
}) => {
  const [validated, setValidated] = useState(false);
  const [attributesTable, setAttributesTable] = useState([]);
  const [attributesFromDb, setAttributesFromDb] = useState([]);
  const [images, setImages] = useState(false);
  const [isCreating, setIsCreating] = useState("");
  const [createProductResponseState, setCreateProductResponseState] = useState({
    message: "",
    error: "",
  });
  const [categoryChoosen, setCategoryChoosen] = useState("Choose category");

  const [newAttrKey, setNewAttrKey] = useState(false);
  const [newAttrValue, setNewAttrValue] = useState(false);

  const attrVal = useRef(null);
  const attrKey = useRef(null);
  const createNewAttrKey = useRef(null);
  const createNewAttrVal = useRef(null);

  const [showAlertConfirmDelete, setShowAlertConfirmDelete] = useState(false);

  const navigate = useNavigate();

  const [name, setName] = useState("");                      
  const [description, setDescription] = useState("");                      
  const [color, setColor] = useState("");                      
  const [count, setCount] = useState(0);                      
  const [price, setPrice] = useState(0);                      
  const [isBookable, setIsBookable] = useState(false);                      
  const [bookingPrice, setBookingPrice] = useState(0);                      

  const handleSubmit = (event) => {
    
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget.elements;

    const formInputs = {
      name: name,
      description: description,
      color: color,
      count: count,
      price: price,     
      isBookable: isBookable,
      bookingPrice: bookingPrice,
      category: form.category.value,
      attributesTable: attributesTable,
    };

    console.log('formInputs=',formInputs)

    if (event.currentTarget.checkValidity() === true) {
      if (images.length > 3) {
        setIsCreating("to many files");
        return;
      }
      createProductApiRequest(formInputs)
        .then((data) => {
          if (images) {
            if (process.env.NODE_ENV !== "production") {
              uploadImagesApiRequest(images, data.productId)
                .then((res) => {})
                .catch((er) =>
                  setIsCreating(
                    er.response.data.message
                      ? er.response.data.message
                      : er.response.data
                  )
                );
            } else {
              uploadImagesApiRequest(images, data.productId)
                .then((res) => {})
                .catch((er) =>
                  setIsCreating(
                    er.response.data.message
                      ? er.response.data.message
                      : er.response.data
                  )
                );
            }
          }
          if (data.message === "product created") navigate("/admin/products");
        })
        .catch((er) => {
          setCreateProductResponseState({
            error: er.response.data.message
              ? er.response.data.message
              : er.response.data,
          });
        });
    }

    setValidated(true);
  };

  const uploadHandler = (images) => {
    setImages(images);
  };

  const deleteCategoryHandler = () => {
    let element = document.getElementById("cats");
    reduxDispatch(deleteCategory(element.value));
    setCategoryChoosen("Choose category");
  };

  const checkKeyDown = (e) => {
      if (e.code === "Enter") e.preventDefault();
  }

    function gotoIndietro (eventKey) {
      eventKey.preventDefault();
      eventKey.stopPropagation();
  
      window.location.assign('/admin/products')
    }

    const switchIsBookableHandler = (event) => {
      setIsBookable(event.target.checked);
      console.log('[switch] isBookable =',isBookable)
    };

  return (

    <Container>
    
      <Row className="justify-content-md-center mt-5">
    
        <Col md={11}>

          <h1>Crea nuovo servizio</h1>
        
          <Form noValidate validated={validated} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)} >

            <Box
              sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
              noValidate
              autoComplete="off"
            >

              <TextField id="id-name" label="Nome" variant="outlined" 
                style={{
                  width: "400px",
                }}
                onChange={(event) => {
                  setName(event.target.value);
                }}
              />

              <TextField id="id-description" label="Descrizione" variant="outlined" 
                  style={{
                    width: "400px",
                  }}
                  onChange={(event) => {
                    setDescription(event.target.value);
                  }}
                />

              <TextField id="id-color" label="Colore" variant="outlined" type="color"
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setColor(event.target.value);
                }}
              />

              <TextField id="id-count" label="Quantità" variant="outlined" type="number"
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setCount(event.target.value);
                }}
              />

              <FormControl sx={{ m: 1 }}
                style={{
                  width: "200px",
                }}    
                onChange={(event) => {
                  setPrice(event.target.value);
                }}        
              >
                <InputLabel htmlFor="outlined-adornment-amount">Prezzo</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  startAdornment={<InputAdornment position="start">€</InputAdornment>}
                  label="Prezzo"
                  type="number"
                />
              </FormControl>

              <Row> 
                <Col md={8}>
                  <FormControlLabel 
                    control={<Switch defaultChecked />} 
                    checked={isBookable} 
                    onChange={switchIsBookableHandler} 
                    label="IsBookable" 
                  />           
                </Col>

                <Col md={8}>
                  <Alert
                      show={isBookable}
                      variant="success"
                      onClose={() => setShowAlertConfirmDelete(false)} 
                      style={{
                        width: "40ch",
                      }}    
                >              

                    <FormControl sx={{ m: 1 }}
                        style={{
                          width: "20ch",
                        }}    
                        onChange={(event) => {
                          setBookingPrice(event.target.value);
                        }}        
                      >
                        <InputLabel htmlFor="outlined-adornment-amount">Importo prenotazione</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-amount"
                          startAdornment={<InputAdornment position="start">€</InputAdornment>}
                          label="Importo prenotazione"
                          type="number"
                        />
                    </FormControl>
                  </Alert>
              </Col>

            </Row>


            </Box>

            <Form.Group className="mb-3" controlId="formBasicCategory">
              <Form.Label
                style={{
                  color: "blue",
                }}
              >
                Categoria
                <CloseButton onClick={deleteCategoryHandler} />(
                <small>rimuovi selezione</small>)
              </Form.Label>
              <Form.Select
                style={{
                  width: "400px",
                }}
                id="cats"
                required
                name="category"
                aria-label="Default select example"
                onChange={(e) =>
                  changeCategory(
                    e,
                    categories,
                    setAttributesFromDb,
                    setCategoryChoosen
                  )
                }
              >
                <option value="">Scegli categoria</option>
                {categories.map((category, idx) => (
                  <option key={idx} value={category.name}>
                    {category.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formFileMultiple" className="mb-3 mt-3">
              <Form.Label                 
                style={{
                  color: "blue",
                }}
              >
                Immagini
              </Form.Label>
              <Form.Control
                style={{
                  width: "400px",
                }}
                required
                type="file"
                multiple
                onChange={(e) => uploadHandler(e.target.files)}
              />
              {isCreating}
            </Form.Group>

            <Row className="justify-content-center mt-5">
            
              <Col md={2}>
                <Button 
                    type="button"
                    className="btn btn-warning"
                    onClick={(e) => {gotoIndietro(e); }}
                    style={{
                      width: "80px",
                    }}
                  >
                    Indietro
                </Button>  
              </Col>

              <Col md={2}>
                <Button variant="primary" type="submit">
                  Crea
                </Button>
                {createProductResponseState.error ?? ""}
              </Col>
              
            </Row>

          </Form>
        </Col>
      </Row>

      <Row className="mt-5 justify-content-md-center">
        <Col md={6}>
        </Col>
      </Row>

    </Container>
  );
};

export default CreateProductPageComponent;
