import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { InputGroup } from "react-bootstrap";

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { red } from "@mui/material/colors";

import { strDateITAfromDayJs, strDateUSAfromDayJs, strDateITAfromStrUSA, strDateUSAfromStrITA } from '../../../utils/dateUtils.js'
const { strCurrency, strEuroCurrency } = require('../../../utils/currencyUtils.js')

const EditPaymentPageComponent = ({ updatePaymentApiRequest, fetchPayment }) => {

  const [validated, setValidated] = useState(false);
  const [payment, setPayment] = useState([]);
  const [updatePaymentResponseState, setUpdatePaymentResponseState] = useState({ message: "", error: "" });

  const { id } = useParams();
  const navigate = useNavigate();

  const [amount, setAmount] = useState(0);                      
  const [date, setDate] = useState("");                      
  const [hour, setHour] = useState(""); 
  const [cardName, setCardName] = useState(""); 
  const [product, setProduct] = useState(""); 
  const [category, setCategory] = useState(""); 
  const [cardBrand, setCardBrand] = useState(""); 
  const [cardLast4, setCardLast4] = useState(""); 
  
  useEffect(() => {
    fetchPayment(id)
    .then(data => {
      console.log(`data =`, data);  
      setAmount(data?.amount);
      setDate(data?.date);
      setHour(data?.hour);
      setCardName(data?.card.name);
      setProduct(data?.productDescription);
      setCategory(data?.categoryDescription);
      setCardBrand(data?.card.brand);
      setCardLast4(data?.card.last4);

      console.log(`amount  =`, amount);  
      console.log(`date  =`, date);  
    })
    .catch((er) => console.log(er.response.data.message ? er.response.data.message : er.response.data));         
},[id])

function salvaPaymentModificata (eventKey) {
  eventKey.preventDefault();
  eventKey.stopPropagation();

  /*
  console.log(`title dig =`, title);  
  console.log(`name dig =`, name);  
  console.log(`surname dig =`, surname);  
  console.log(`telephone dig =`, telephone);  
  console.log(`email dig =`, email);  
  console.log(`address dig =`, address);  
  console.log(`code dig =`, code);  
  console.log(`color dig =`, color);  
  console.log(`image dig =`, image);  

  updatePaymentApiRequest(id, title, name, surname, telephone, email, address, code, color, image)
  .then(data => {
      if (data.success === "payment updated") {
          navigate("/admin/payments");
      }
    })
  .catch(er => {
      setUpdatePaymentResponseState({ error: er.response.data.message ? er.response.data.message : er.response.data });
  })
  */

  window.location.assign('/admin/payments')
}

function gotoIndietro (eventKey) {
  eventKey.preventDefault();
  eventKey.stopPropagation();

  window.location.assign('/admin/payments')
}


  return (
    <Container fullWidth>

      <Row className="justify-content-center mt-5">

        <Col md={11}>
          <h1>&nbsp;Vedi Pagamento</h1>

          <Form noValidate validated={validated} >

            <Box
              component="form"
              sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
              noValidate
              autoComplete="off"
            >

              <TextField id="id-amount" label="Importo" variant="outlined" value={strEuroCurrency(amount)} defaultValue={strEuroCurrency(amount)}
                style={{
                  width: "150px",
                }}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(event) => {
                  setAmount(event.target.value);
                }}
              />

              <TextField id="id-date" label="Data" variant="outlined" value={strDateITAfromStrUSA(date)} defaultValue={strDateITAfromStrUSA(date)}
                style={{
                  width: "150px",
                }}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(event) => {
                  setDate(event.target.value);
                }}
              />

              <TextField id="id-hour" label="Ora" variant="outlined" value={hour} defaultValue={hour} 
                style={{
                  width: "150px",
                }}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(event) => {
                  setHour(event.target.value);
                }}
              />

              <TextField id="id-cardName" label="Eseguito da" variant="outlined" value={cardName} defaultValue={cardName} 
                style={{
                  width: "400px",
                }}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(event) => {
                  setCardName(event.target.value);
                }}
              />

              <TextField id="id-product" label="Prodotto acquistato" variant="outlined" value={product} defaultValue={product} 
                style={{
                  width: "300px",
                }}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(event) => {
                  setProduct(event.target.value);
                }}
              />

              <TextField id="id-category" label="Categoria" variant="outlined" value={category} defaultValue={category} 
                style={{
                  width: "300px",
                }}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(event) => {
                  setCategory(event.target.value);
                }}
              />

              <TextField id="id-cardBrand" label="Tipo carta" variant="outlined" value={cardBrand} defaultValue={cardBrand} 
                style={{
                  width: "150px",
                }}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(event) => {
                  setCardBrand(event.target.value);
                }}
              />

              <TextField id="id-numcarta" label="Numero" variant="outlined" value={'***'+cardLast4} defaultValue={'***'+cardLast4} 
                style={{
                  width: "100px",
                }}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(event) => {
                  setCardBrand(event.target.value);
                }}
              />

            </Box>

          </Form>

        </Col>

      </Row>

      <Row className="justify-content-center mt-5">
        <Col md={1}>
  
        <Button 
              type="button"
              className="btn btn-warning"
              onClick={(e) => {gotoIndietro(e); }}
              style={{
                width: "80px",
              }}
            >
              Indietro
            </Button>             

         </Col>

          <Col md={1}>
          {/* 
            <Button 
              type="button"
              className="btn btn-primary"
              onClick={(e) => {salvaPaymentModificata(e); }}
              style={{
                width: "80px",
              }}
            >
              Salva
            </Button>             
          */}
          </Col>
      </Row>

      <Row className="mt-5 justify-content-md-center"></Row>

    </Container>
  );
};

export default EditPaymentPageComponent;
